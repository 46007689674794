import React from 'react';
import '../style/Product.css';
import { FaCloud, FaMobileAlt, FaBuilding, FaChalkboardTeacher, FaSchool, FaCode } from 'react-icons/fa';

import saasImage from '../images/Igermitech_saas.jpg';
import mobileImage from '../images/MobileLMS.png';
import corporateImage from '../images/CorporateLMS.jpg';
import blendedImage from '../images/BlendedLMS.jpg';
import enterpriseImage from '../images/EnterpseLMS.jpg';
import customImage from '../images/CustomLMS.jpg';

const Product = () => {
  return (
    <div className="product-page">
      <h1>Learning Management Systems (LMS)</h1>
      <p>Learning Management Systems (LMS) come in various types, each tailored to specific needs and contexts. Let’s explore some major LMS types:</p>

      <div className="product-cards">
        <div className="product-card">
          <img src={saasImage} alt="SaaS LMS" className="product-image" />
          <div className="product-info">
            <FaCloud className="product-icon" />
            <h3>SaaS LMS (Cloud-Based LMS)</h3>
            <p>Hosted on the provider’s servers, accessible via the web. Quick deployment and subscription-based pricing models.</p>
          </div>
        </div>

        <div className="product-card">
          <img src={mobileImage} alt="Mobile LMS" className="product-image" />
          <div className="product-info">
            <FaMobileAlt className="product-icon" />
            <h3>Mobile LMS</h3>
            <p>Designed for mobile devices, enabling learning on the go. Supports responsive design and app-based access.</p>
          </div>
        </div>

        <div className="product-card">
          <img src={corporateImage} alt="Corporate LMS" className="product-image" />
          <div className="product-info">
            <FaBuilding className="product-icon" />
            <h3>Corporate LMS</h3>
            <p>Focuses on professional development within organizations. Tracks employee training, compliance, and skill development.</p>
          </div>
        </div>

        <div className="product-card">
          <img src={blendedImage} alt="Blended Learning LMS" className="product-image" />
          <div className="product-info">
            <FaChalkboardTeacher className="product-icon" />
            <h3>Blended Learning LMS</h3>
            <p>Combines online and in-person learning. Ideal for hybrid or flipped classroom models.</p>
          </div>
        </div>

        <div className="product-card">
          <img src={enterpriseImage} alt="Enterprise LMS" className="product-image" />
          <div className="product-info">
            <FaSchool className="product-icon" />
            <h3>Enterprise LMS</h3>
            <p>Scalable platforms for large organizations. Customizable features, robust reporting, and user management.</p>
          </div>
        </div>

        <div className="product-card">
          <img src={customImage} alt="Custom LMS" className="product-image" />
          <div className="product-info">
            <FaCode className="product-icon" />
            <h3>Custom LMS</h3>
            <p>Tailored solutions built from scratch or modified existing systems. Addresses unique organizational needs.</p>
          </div>
        </div>
      </div>
    
      <section className="virtual-learning-section">
        <h2>Virtual Learning</h2>
        <p>Virtual learning is a valuable endeavor. Here are some key points we consider:</p>
        
        <h3>Challenges and Adaptations</h3>
        <p>Educators were facing challenges during COVID-19 pandemic. Virtual Learning teachers adapted by finding creative solutions to support student learning.</p>

        <h3>Digital Tools and Benefits</h3>
        <p>Mindset shift from using technology merely to replicate in-person learning to leveraging it for unique virtual opportunities. 
        
        Digital tools and virtual environments positively impact teaching and learning.</p>

        <h3>Student Engagement and Interaction</h3>
        <p>Our strategies for fostering student engagement in virtual classrooms using the tools replicate meaningful interactions and emotional energy that come from in-person teaching.</p>

        <h3>Balancing Flexibility and Structure</h3>
        <p>We address the balance between flexibility (which virtual learning allows) and maintaining structure for effective learning outcomes.</p>
      </section>
    
    </div>
  );
};

export default Product;
