/*import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/

// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import PageTitleBar from './components/PageTitleBar';
import About from './pages/About';
import Insights from './pages/Insights';
import Careers from './pages/Careers';
import Product from './pages/Product';
//import Platform from './pages/Platform';
import Industry from './pages/Industry';
import Services from './pages/Services';
import ContactUs from './pages/ContactUs';
import Footer from './components/Footer';
import Home from './pages/Home';
import BlogBPITField from './pages/BlogBPITField';
import BlogLMS from './pages/BlogLMS';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
       <PageTitleBar />
        <Routes>
          <Route path="/" element={<Home />} /> 
          <Route path="/about" element={<About/>} />
          <Route path="/insights" element={<Insights/>} />
          <Route path="/careers" element={<Careers/>} />
          <Route path="/product" element={<Product/>} />
     { /*   <Route path="/platform" element={<Platform/>} /> */}
       <Route path="/industry" element={<Industry/>} />
          <Route path="/services" element={<Services/>} />
         <Route path="/contactus" element={<ContactUs/>} />
       <Route path="/blog-bestpracticesit" element={<BlogBPITField/>} />
      <Route path="/blog-lms" element={<BlogLMS/>} />
        </Routes>
        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
