// Home.js
/*import React from 'react';
//import './Home.css'; // Import the CSS file for styling

const Home = () => {
  return (
    <div className="hero-container">
      <div className="hero-text">
        <h1>Welcome to Our Website</h1>
        <p>Discover the power of our services.</p>
        <button>Contact Us</button>
      </div>
    </div>
  );
};

export default Home;*/

// Home.js
import React from 'react';
import '../style/Home.css'; // Import the CSS file for styling
import trends from '../images/trends.jpg'
import centrifugal from '../images/centrifugal.jpg'
import continuouslearning from '../images/continuouslearning.jpg'
import democratization from '../images/democratization.jpg'
import datatrust from '../images/datatrust.jpg'
import innovationcodeblock from '../images/innovationcodeblock.jpg'
import { useNavigate   } from 'react-router-dom';

const Home = () => {
    
    const navigate = useNavigate();
  const navigateTo = () => navigate('/contactus');;//eg.history.push('/login');

    
  return (
      

       <div className="home-container">
    <div className="hero-container">
            <div className="hero-text">
        <h1>Welcome to IGermiTech Corporation</h1>
        <p>Discover the power of our services.</p>
        <button onClick={navigateTo}  > Contact Us</button>
     
            </div>
      
      </div>
      <div className="home-header">
        <h1>Innovation at the Edge</h1>
      </div>
      <div className="home-content">
        <div className="content-item">
          <img src={trends} alt="Tech Trends" />
          <h2>Tech Trends</h2>
          <p>The rapid evolution of technology is reshaping the future of IT and business. Trends like cloud computing, 5G networks, AI, and low-code development platforms are accelerating change.</p>
        </div>
        <div className="content-item">
          <img src={centrifugal} alt="Centrifugal Force" />
          <h2>Centrifugal Force</h2>
          <p>Technology’s increasing power is pushing innovation to the edges of organizations. Expert networks outside traditional hierarchies are driving creativity and problem-solving.</p>
        </div>
        <div className="content-item">
          <img src={continuouslearning} alt="Continuous Learning" />
          <h2>Continuous Learning</h2>
          <p>To keep up with the pace of innovation, companies need radical approaches to learning. Skills deployed at points of need become critical for adaptability.</p>
        </div>
        <div className="content-item">
          <img src={democratization} alt="Democratization" />
          <h2>Democratization</h2>
          <p>IT can no longer act as a centralized controller; it must become an enabler and influencer, empowering teams across the organization.</p>
        </div>
        <div className="content-item">
          <img src={datatrust} alt="Data and Trust" />
          <h2>Data and Trust</h2>
          <p>New technologies generate more data and touchpoints with customers, reshaping trust boundaries and security responsibilities.</p>
        </div>
        <div className="content-item">
          <img src={innovationcodeblock} alt="Product Innovation" />
          <h2>Product Innovation through Code Blocks</h2>
          <p>IT shifts from managing monolithic systems to serving small, interoperable blocks of code. Platforms that allow rapid application development are gaining prominence. Hybrid Cloud and 5G exponentially increase compute power and network speeds, enabling greater innovation.</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
