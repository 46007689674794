import React from 'react';
import '../style/Industry.css';

import educationImage from '../images/eduIndustr.jpg';
import itImage from '../images/ittech.jpg';
import manufacturingImage from '../images/manufacturing.jpg';
import consumerGoodsImage from '../images/consumergoods.jpg';

const Industry = () => {
  return (
    <div className="industry-page">
      <section className="industry-section education">
        <img src={educationImage} alt="Education" className="industry-header-image" />
        <h2>Education</h2>
        <div className="industry-content">
          <div className="industry-card">
            <h3>Elementary Schools and Secondary Schools</h3>
            <p>Elementary schools cater to children from kindergarten through sixth grade. Secondary schools include both junior high and senior high levels.</p>
          </div>
          <div className="industry-card">
            <h3>Community Colleges and Universities</h3>
            <p>Community colleges offer two-year programs and associate degrees. Universities provide undergraduate, graduate, and postgraduate education.</p>
          </div>
          <div className="industry-card">
            <h3>Ministries or Departments of Education</h3>
            <p>These government bodies oversee educational policies, curriculum development, and funding.</p>
          </div>
          <div className="industry-card">
            <h3>Other Constituents</h3>
            <p>Charter Schools, Online Academies, Vocational Centers, and Corporate Educational Support Services play significant roles in the education sector.</p>
          </div>
        </div>
      </section>

      <section className="industry-section it">
        <img src={itImage} alt="Information Technology" className="industry-header-image" />
        <h2>Information Technology</h2>
        <div className="industry-content">
          <div className="industry-card">
            <h3>IT Services</h3>
            <p>IT services play a crucial role in managing and optimizing information and business processes. They cover managed services, security services, data management, and cloud computing.</p>
          </div>
          <div className="industry-card">
            <h3>Market Trends</h3>
            <p>Rapid growth due to cloud computing, digital technologies, and high demand for cybersecurity solutions. AI, machine learning, data analytics, and IoT further fuel this growth.</p>
          </div>
          <div className="industry-card">
            <h3>Application Areas</h3>
            <p>IT services are used in systems and network management, data management, application management, and security and compliance management.</p>
          </div>
          <div className="industry-card">
            <h3>Industry Verticals</h3>
            <p>IT services cater to sectors like BFSI, consumer goods, healthcare, government, and telecom.</p>
          </div>
        </div>
      </section>

      <section className="industry-section manufacturing">
        <img src={manufacturingImage} alt="Manufacturing" className="industry-header-image" />
        <h2>Manufacturing</h2>
        <div className="industry-content">
          <div className="industry-card">
            <h3>Definition</h3>
            <p>Manufacturing involves processing and refining raw materials into finished products, adding value to the materials.</p>
          </div>
          <div className="industry-card">
            <h3>Types of Manufacturing</h3>
            <p>Includes manual labor, machinery, and division of labor. Contemporary manufacturing encompasses all stages of producing and integrating components.</p>
          </div>
          <div className="industry-card">
            <h3>Challenges and Opportunities</h3>
            <p>Manufacturers face economic uncertainty, labor shortages, and supply chain disruptions. Digital transformation and smart factories can address these challenges.</p>
          </div>
        </div>
      </section>

      <section className="industry-section consumer-goods">
        <img src={consumerGoodsImage} alt="Consumer Goods" className="industry-header-image" />
        <h2>Consumer Goods</h2>
        <div className="industry-content">
          <div className="industry-card">
            <h3>Durable vs. Nondurable Goods</h3>
            <p>Durable goods are long-lasting items like cars and appliances. Nondurable goods have shorter lifespans, like packaged foods and toiletries.</p>
          </div>
          <div className="industry-card">
            <h3>Marketing and Brand Differentiation</h3>
            <p>Heavily rely on advertising and brand strategies. Developing new flavors, fashions, and styles is essential to stay competitive.</p>
          </div>
          <div className="industry-card">
            <h3>Technological Trends</h3>
            <p>Modern technology impacts manufacturing, distribution, marketing, and sales. Internet technology continues to shape consumer behavior and demand.</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Industry;
