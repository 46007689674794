// Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
//import './footer.css'; // Import the CSS file for footer styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-row">
          <div className="footer-col">
            <h4>IGermiTech Corporation </h4>
            <p>IGermiTech is an IT services consulting and business solutions organization that has been partnering with many businesses. We believe innovation and building technology can improve human life.</p>
          </div>
          <div className="footer-col">
            <h4>company</h4>
            <ul>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/about">Mantra</Link></li>
          { /*  <li><Link to="privacypolicy" target="_blank">privacy and policy</Link></li>
              <li><Link to="terms" target="_blank">Terms and Services</Link></li>
              <li><Link to="terms" target="_blank">Disclaimer</Link></li> */ }
            </ul>
          </div>
          <div className="footer-col">
            <h4> Product </h4>
            <ul>
              <li><Link to="/product">Learning Managment System</Link></li>
          {/*    <li><Link to="#capability-capacity">IT Consulting Services</Link></li>
               <li><Link to="#capability-capacity">Custom Software Development</Link></li>
        <li><Link to="#capability-capacity">Cloud Services</Link></li> */ } 
            </ul>
          </div>
                <div className="footer-col">
            <h4>Industry</h4>
            <ul>
              <li><Link to="/industry">Education</Link></li>
              <li><Link to="/industry">Information Technology</Link></li>
              <li><Link to="/industry">Manufacturing</Link></li>
              <li><Link to="/industry">Consumer Goods</Link></li>
              
            </ul>
          </div>
      { /*   <div className="footer-col">
            <h4>Platform</h4>
            <ul>
              <li><Link to="#field-we-serve">Integrated Solution</Link></li>
              <li><Link to="#field-we-serve">Scalable Infrastructure</Link></li>
              <li><Link to="#field-we-serve">Secure Environment</Link></li>
              <li><Link to="#field-we-serve">Customization Options</Link></li>
              <li><Link to="#field-we-serve">Continuous Innovation</Link></li>
              <li><Link to="#field-we-serve">Expert Support</Link></li>
            </ul>
          </div> */ } 
          <div className="footer-col">
            <h4>Our Services</h4>
            <ul>
              <li><Link to="/services">IT consulting </Link></li>
              <li><Link to="/services">Software Development</Link></li>
              <li><Link to="/services">Cloud Solution</Link></li>
              <li><Link to="/services">Managed Services</Link></li>
             <li><Link to="/services">Quality Control </Link></li>
             <li><Link to="/services">Training and Support</Link></li>
            </ul>
          </div>
        </div>
        <div className="footer-row">
          <div className="footer-col">
            <h4>help and contact</h4>
            <ul>
              <li><Link to="/insights">Insights</Link></li>
              <li><Link to="/contactus">Contact Us</Link></li>
              <li><Link to="/careers">Careers</Link></li>
            </ul>
          </div>
          <div className="footer-col">
            <h4>Our Sales Team</h4>
            <dl>
              <dt>Sales</dt>
              <dd><Link to="mailto:sales@igermitech.com">sales@igermitech.com</Link></dd>
              <dt>Marketing</dt>
              <dd><Link to="mailto:marketing@igermitech.com">marketing@igermitech.com</Link></dd>
            </dl>
          </div>
          <div className="footer-col">
            <h4>Our Support</h4>
            <dl>
              <dt>General Support</dt>
              <dd><Link to="mailto:support@companyname.com">support@igermitech.com</Link></dd>
              <dt>Directors</dt>
              <dd><Link to="mailto:info@companyname.com">info@igermitech.com</Link></dd>
              <dt>HR Support</dt>
              <dd><Link to="mailto:support@companyname.com">support@igermitech.com</Link></dd>
            </dl>
          </div>
       {/*  <div className="footer-col">
            <h4>Stay Connected</h4>
            <div className="social-links">
              <Link to="https://www.facebook.com/profile.php?id=61558121400800" target="_blank"><i className="fab fa-facebook-f"></i></a>
              <Link to="https://twitter.com/companyname" target="_blank"><i className="fab fa-twitter"></i></a>
              <Link to="https://www.instagram.com/companyname/?igsh=Ynk0Z3R3b3kyaXd5" target="_blank"><i className="fab fa-instagram"></i></a>
              <Link to="https://www.linkedin.com/in/companyname-digital-learning-center-143285302/" target="_blank"><i className="fab fa-linkedin-in"></i></a>
              <Link to="https://www.youtube.com/@companyname/about" target="_blank"><i className="fab fa-youtube"></i></a>
            </div>
          </div> */ }
        </div>
        <div className="footer-row">
          <p>Powered By IGermiTech Corporation @ 2024</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
