// ContactUs.js
import React, { useState } from 'react';
import axios from 'axios';
//import './contactUs.css'; // Import the CSS file
//import Footer from './Footer';

   const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

const [successMessage, setSuccessMessage] = useState(''); 
    
   

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
    
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${backendUrl}/api/contact-us`, formData);
      console.log(response.data); // Log the response from the server
        setSuccessMessage('Your message has been successfully sent!');
      // Reset form fields after successful submission
      setFormData({ name: '', email: '', message: '' });
      // Optionally, display a success message to the user
    } catch (error) {
      console.error('Error submitting form:', error);
      // Optionally, display an error message to the user
    }
  };

  return (
    <div className="contact-container">
      <h2>Contact Us</h2>
      <p>Have questions or inquiries? Reach out to us using the form below:</p>
       {successMessage && <p className="success-message">{successMessage}</p>}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message:</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default ContactUs;
