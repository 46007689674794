// About.js
import React from 'react';
import '../style/About.css'; // Import the CSS file for styling
import about from '../images/logo_name.png';
import healthcareImage from '../images/healthcare.jpg';
import communicationImage from '../images/communication.jpg';
import educationImage from '../images/educationlearning.jpg';
import technologyImage from '../images/technology.jpg';
import sustainabilityImage from '../images/sustainablility.jpg';

const About = () => {
  return (
       <div className="about-container">
    <div className="about-section">
        <div class="left-side">
      <h2>IMPROVE HUMAN LIFE THROUGH INNOVATION, GERMINATE, AND TECHNOLOGY.</h2>
       <p>IGermiTech is an IT services consulting and business solutions organization that has been partnering with many businesses. We believe innovation and building technology can improve human life. </p>
    
            <h2>Our Mantra  </h2>
       <p>Innovative , Germinate , Technology </p>
      </div>
        <div class="right-side">
     <img src={about} alt="Left Side Image"/>
    <p></p>
  </div>
       </div>
         <div>
        <h2>Innovation: The Catalyst for Progress</h2>
        <p>
          Innovation is the spark that ignites progress. It’s the art of turning ideas into reality, pushing boundaries, and challenging the status quo. Here’s how innovation shapes our lives:
        </p>
      </div>
      
      <div className="about-section">
          <div class="left-side">
          <h2>Healthcare Advancements</h2>
          <p>
            Innovations in medicine, diagnostics, and treatments save lives. Vaccines, minimally invasive surgeries, and personalized therapies improve health outcomes.
          </p>
   {/*  <button class="book-now-button"  onclick="scrollToEnroll()">Request a Quote</button> */ }
        </div>
        <div class="right-side">
        <img src={healthcareImage} alt="Healthcare Advancements" />
      </div>
      </div>

      <div className="about-section">
       <div class="left-side">
         <img src={communicationImage} alt="Communication Revolution" />
        </div>
       <div class="right-side">
        
       <h2>Communication Revolution</h2>
          <p>
            From telegrams to smartphones, innovation transformed how we connect. Instant messaging, video calls, and social media bridge distances.
          </p>
      </div>
      </div>

      <div className="about-section">
          <div class="left-side">
          <h2>Education and Lifelong Learning</h2>
          <p>
            E-learning platforms, virtual classrooms, and interactive content democratize education. Lifelong learning keeps minds sharp and adaptable.
          </p>
        </div>
      <div class="right-side">
        <img src={educationImage} alt="Education and Lifelong Learning" />
      </div>
      </div>

      <div className="about-section">
        <div class="left-side">
             <img src={technologyImage} alt="Technology: Our Silent Partner" />
        </div>
       <div class="right-side">
       <h2>Technology: Our Silent Partner</h2>
          <p>
            Technology is the silent force that permeates our daily existence. Automation, cloud computing, and data analytics optimize business processes. Smart homes, wearables, and IoT enhance convenience.
          </p>
    
      </div>
      </div>

      <div className="about-section">
        <div class="left-side">
          <h2>Sustainability Solutions</h2>
          <p>
            Renewable energy, electric vehicles, and circular economy models combat climate change. Technology empowers us to be eco-conscious.
          </p>
        </div>
      <div class="right-side">
        <img src={sustainabilityImage} alt="Sustainability Solutions" />
      </div>
      </div>

      <div>
        <h2>The Promise of Tomorrow</h2>
        <p>
          As we stand on the cusp of the future, let’s embrace innovation and wield technology responsibly. Together, we can create a world where human life flourishes, fueled by curiosity, compassion, and progress.
        </p>
      </div>
    </div>
  );
};

export default About;