import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../style/Navbar.css'; // Import the CSS file for styling
import logoname from '../images/igtlogo_name.png';

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <Link to="/" className="navbar-logo">
                    <img src={logoname} alt="Logo" className="logo-img"/>
        i
                </Link>
                <div className="menu-icon" onClick={toggleMenu}>
                    <i className={isOpen ? 'fas fa-times' : 'fas fa-bars'}></i>
                </div>
                <ul className={isOpen ? 'nav-menu active' : 'nav-menu'}>
                    <li className="nav-item">
                        <Link to="/" className="nav-links" onClick={() => setIsOpen(false)}>
                            Home
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/about" className="nav-links" onClick={() => setIsOpen(false)}>
                            About
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/insights" className="nav-links" onClick={() => setIsOpen(false)}>
                            Insights
                        </Link>
                     </li>
                    <li className="nav-item">
                        <Link to="/careers" className="nav-links" onClick={() => setIsOpen(false)}>
                            Careers
                        </Link>
                     </li>
                    <li className="nav-item">
                        <Link to="/product" className="nav-links" onClick={() => setIsOpen(false)}>
                            Product
                        </Link>
                     </li>
                    <li className="nav-item">
                        <Link to="/industry" className="nav-links" onClick={() => setIsOpen(false)}>
                            Industry
                        </Link>
                     </li>

                    <li className="nav-item">
                        <Link to="/services" className="nav-links" onClick={() => setIsOpen(false)}>
                            Services
                        </Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contactus" className="nav-links" onClick={() => setIsOpen(false)}>
                            Contact Us
                        </Link>
                    </li>

                </ul>
            </div>
        </nav>
    );
};

export default Navbar;
