// Services.js
import React from 'react';
import '../style/Services.css'; // Import the CSS file for styling

import itConsultingImage from '../images/itconsulting.jpg';
import softwareDevelopmentImage from '../images/softwaredevelopment.jpg';
import cloudSolutionsImage from '../images/cloud.jpg';
import managedServicesImage from '../images/managedservice.jpg';
import trainingSupportImage from '../images/training.jpg';

const Services = () => {
  return (
    <div className="services-container">
      <div className="service-section">
        <h2>IT Consulting</h2>
        <img src={itConsultingImage} alt="IT Consulting" />
        <p>
          Benefit from strategic IT consulting services to drive digital transformation and achieve your business objectives.
        </p>
        <h3>Strategic IT Consulting:</h3>
        <ul>
          <li>IT consultants work closely with businesses to understand their objectives, challenges, and future plans.</li>
          <li>They analyze the current IT infrastructure, processes, and systems to identify areas for improvement and potential opportunities.</li>
          <li>Their approach includes technology road mapping, resource optimization, risk assessment, and cost-effective implementation plans.</li>
          <li>The goal is to create a future-proof and scalable IT strategy that enhances operational efficiency and customer experiences.</li>
        </ul>
        <h3>Digital Transformation Services:</h3>
        <ul>
          <li>IT consulting helps organizations adopt contemporary digital technologies (such as cloud computing, AI, IoT, and data analytics).</li>
          <li>Consultants guide businesses through every stage of the transformation process, ensuring alignment with core business goals.</li>
          <li>Services include identifying opportunities, developing a strategy, implementing changes, and measuring success.</li>
        </ul>
        <h3>Technology Consulting:</h3>
        <ul>
          <li>Strategic partnerships empower organizations to harness cutting-edge solutions for rapid growth.</li>
          <li>Consultants provide expertise in areas like architecture, implementation, and optimization.</li>
          <li>The focus is on supporting business and technology initiatives, driving innovation, and unlocking new revenue streams.</li>
        </ul>
      </div>

      <div className="service-section">
        <h2>Software Development</h2>
        <img src={softwareDevelopmentImage} alt="Software Development" />
        <p>
          Unlock innovation with custom software development solutions tailored to your unique requirements.
        </p>
        <h3>Conception and Feasibility Evaluation:</h3>
        <ul>
          <li>The process begins with conceiving the software project. This includes understanding the problem to be solved and evaluating its feasibility.</li>
          <li>Business requirements are analyzed to determine if the software solution aligns with organizational goals.</li>
        </ul>
        <h3>Software Design:</h3>
        <ul>
          <li>During this phase, architects and designers create a blueprint for the software. They define its structure, components, and interactions.</li>
          <li>Design decisions impact the software’s functionality, scalability, and maintainability.</li>
        </ul>
        <h3>Programming (Source Code):</h3>
        <ul>
          <li>Programmers (or coders) write the actual source code. They use programming languages like C++, Java, or Python to implement specific tasks.</li>
          <li>Source code is the set of instructions that tell a computer what to do.</li>
        </ul>
        <h3>Testing and Quality Assurance:</h3>
        <ul>
          <li>Rigorous testing ensures that the software functions correctly, meets requirements, and is free of defects.</li>
          <li>Quality assurance processes include unit testing, integration testing, and user acceptance testing.</li>
        </ul>
        <h3>Deployment and Release:</h3>
        <ul>
          <li>Once tested and approved, the software is deployed to production environments.</li>
          <li>Regular updates and maintenance are essential to address issues and enhance features.</li>
        </ul>
        <h3>Types of Software:</h3>
        <ul>
          <li>System Software: Provides core functions (e.g., operating systems, disk management).</li>
          <li>Programming Software: Tools for programmers (text editors, compilers, debuggers).</li>
          <li>Application Software (Apps): Helps users perform specific tasks (e.g., office suites, media players, web/mobile apps).</li>
          <li>Embedded Software: Controls devices (telecom networks, cars, industrial robots) and connects to the Internet of Things (IoT).</li>
        </ul>
      </div>

      <div className="service-section">
        <h2>Cloud Solutions</h2>
        <img src={cloudSolutionsImage} alt="Cloud Solutions" />
        <p>
          Optimize your IT infrastructure with scalable and secure cloud solutions for enhanced flexibility and efficiency.
        </p>
        <h3>Cloud Deployment Models:</h3>
        <ul>
          <li>Public Cloud: Public clouds provide resources (compute, storage, networking, applications) over the internet. They are owned and operated by third-party providers like Google Cloud.</li>
          <li>Private Cloud: Built and used by a single organization, private clouds offer greater control and customization. They can be located on-premises.</li>
          <li>Hybrid Cloud: Combines private and public clouds, allowing you to leverage resources from different environments.</li>
        </ul>
        <h3>Cloud Service Models:</h3>
        <ul>
          <li>Infrastructure as a Service (IaaS): Offers on-demand infrastructure (compute, storage, networking). You manage applications and data.</li>
          <li>Platform as a Service (PaaS): Provides hardware and software resources for application development. Developers focus on coding without managing infrastructure.</li>
          <li>Software as a Service (SaaS): Delivers full application stacks (e.g., email, collaboration tools) directly to users over the internet.</li>
          <li>Serverless Computing: Event-triggered functions that automatically scale without managing servers.</li>
        </ul>
        <h3>Multicloud Environment:</h3>
        <ul>
          <li>Nearly 90% of companies adopt a multicloud approach, combining services from different cloud providers (public or private). It offers flexibility and reduces vendor lock-in.</li>
        </ul>
      </div>

      <div className="service-section">
        <h2>Managed Services</h2>
        <img src={managedServicesImage} alt="Managed Services" />
        <p>
          Ensure the reliability and performance of your IT systems with our comprehensive managed services offerings.
        </p>
        <h3>Daily Operations Handling:</h3>
        <ul>
          <li>Managed services (MS) are designed to handle the day-to-day operations of specialized applications.</li>
          <li>By outsourcing these tasks, companies can focus on their core competencies while seasoned professionals manage critical functions.</li>
        </ul>
        <h3>Benefits of Managed Services:</h3>
        <ul>
          <li>Enhanced Capability: MS provides additional capability to end-users within your company.</li>
          <li>Strategic Focus: In-house IT teams can concentrate on more strategic initiatives.</li>
          <li>Cost Efficiency: Outsourcing can be more cost-effective than maintaining an extensive internal IT department.</li>
        </ul>
        <h3>Signs You Need Managed Services:</h3>
        <ul>
          <li>Decline in IT Service Quality: When the repository of knowledge decreases, affecting service quality.</li>
          <li>Service Outages and Downtime: Frequent disruptions impacting productivity.</li>
          <li>Budget Overspending: Excessive spending on application tweaks affecting performance.</li>
          <li>Data Loss: Inability to handle solutions leading to data loss.</li>
        </ul>
        <h3>Choosing a Managed Service Provider (MSP):</h3>
        <ul>
          <li>Consider an MSP when setting future strategic goals.</li>
          <li>Deploying new services or technologies.</li>
          <li>MSPs offer predictable cost models and expertise in managing diverse IT needs.</li>
        </ul>
      </div>

      <div className="service-section">
        <h2>Training and Support</h2>
        <img src={trainingSupportImage} alt="Training and Support" />
        <p>
          Empower your team with training and support services to maximize the value of your IT investments and drive user adoption.
        </p>
        <h3>Customer Service Training:</h3>
        <ul>
          <li>Customer service training is coaching that employees receive to improve support and satisfaction among customers. It covers interpersonal communication, product knowledge, conflict resolution, and crisis management.</li>
          <li>Exceptional customer service is crucial for long-term success. It helps retain customers, reduce employee churn, and create a customer-centric company.</li>
          <li>Anyone interacting with customers, regardless of seniority or role, benefits from customer service training.</li>
        </ul>
        <h3>Software Implementation Training and Support:</h3>
        <ul>
          <li>Training helps users adapt to software changes, new team members, or vendors. It ensures effective use of the software.</li>
          <li>Support prevents issues by providing guidance and troubleshooting from the start.</li>
        </ul>
        <h3>Integrated Product Support (IPS):</h3>
        <ul>
          <li>Training and Training Support: Part of IPS, it includes policies, processes, procedures, and tools for training personnel to acquire, operate, maintain, and support a system.</li>
        </ul>
        <h3>Benefits of Training and Development:</h3>
        <ul>
          <li>Training programs contribute to team success, loyalty, and partnership. They enhance skills and knowledge.</li>
        </ul>
      </div>
    </div>
  );
};

export default Services;
