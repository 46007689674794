import React from 'react';
import { useLocation } from 'react-router-dom';

const PageTitleBar = () => {
    const location = useLocation();

    const getPageTitle = (path) => {
        switch (path) {
            case '/':
                return 'Home';
            case '/about':
                return 'About Us';
            case '/services':
                return 'Our Services';
            case '/contactus':
                return 'Contact Us';
            case '/blog-lms':
                return 'Blog - Learning Management System';
            case '/blog-bestpracticesit':
                return 'Blog - Best Practices in the IT Field';
             case '/insights':
                return 'Insights';
             case '/careers':
                return 'Careers';
             case '/product':
                return 'Our Products';
             case '/industry':
                return 'Industries We Serve';
                
            // Add more cases as needed
            default:
                return 'Page';
        }
    };

    return (
        <div className="page-title-bar">
            <h3>{getPageTitle(location.pathname)}</h3>
        </div>
    );
};

export default PageTitleBar;
