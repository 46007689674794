// Careers.js
import React, { useState, useRef } from 'react';

const Careers = () => {
    
  const [formData, setFormData] = useState({
        name: '',
        email: '',
        position: '',
        attachment: null
    });
    const [successMessage, setSuccessMessage] = useState('');
     const fileInputRef = useRef(null); 
    
    const backendUrl = process.env.REACT_APP_BACKEND_URL;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        setFormData(prevState => ({
            ...prevState,
            attachment: e.target.files[0]
        }));
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const submitData = new FormData();
        submitData.append('name', formData.name);
        submitData.append('email', formData.email);
        submitData.append('position', formData.position);
        submitData.append('attachment', formData.attachment);

        fetch(`${backendUrl}/api/careers/applications`, {
            method: 'POST',
            body: submitData
        })
        .then(response => {
            if (response.ok) {
                setSuccessMessage('Career application submitted successfully!');
                setFormData({
                    name: '',
                    email: '',
                    position: '',
                    attachment: null
                });
                  if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Reset the file input
                }
            } else {
                setSuccessMessage('Failed to submit career application. Please try again.');
            }
        })
        .catch(error => {
            console.error('Error:', error);
            setSuccessMessage('Failed to submit career application. Please try again.');
        });
    };


  return (
    <div>
      <h2>Careers</h2>
      <p>Welcome to our Careers page! Explore exciting opportunities to join our dynamic team and contribute to innovative projects:</p>

      <h3>Current Openings</h3>
      <p>Check out our current job openings and find the perfect role to match your skills and expertise. We're constantly seeking talented individuals who are passionate about technology and eager to make an impact.</p>

      <h3>Why Join Us?</h3>
      <p>Discover the benefits of working with us. From competitive salaries to professional development opportunities, we offer a range of perks and benefits to support the growth and well-being of our employees.</p>

    {/*   <h3>Employee Testimonials</h3>
      <p>Hear from our employees about their experience working with us. Learn about their career growth, challenges they've overcome, and what makes our company a great place to work.</p>
*/ }
      <h3>How to Apply</h3>
      <p>Ready to take the next step in your career? Submit your application today! Fill out the form below and attach your resume.</p>
  {successMessage && <p className="success-message">{successMessage}</p>}
 <form onSubmit={handleSubmit}>
                <label>
                    Name:
                    <input
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                    />
                </label>
                <br />
                <label>
                    Email:
                    <input
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                    />
                </label>
                <br />
                <label>
                    Position:
                    <input
                        type="text"
                        name="position"
                        value={formData.position}
                        onChange={handleChange}
                    />
                </label>
                <br />
                <label>
                    Attachment:
                    <input
                        type="file"
                       name="attachment"
                        onChange={handleFileChange}
                       ref={fileInputRef}
                    />
                </label>
                <br />
                <button type="submit">Submit</button>
            </form>
      

      <h3>Contact Us</h3>
      <p>Have questions about our career opportunities or the application process? Reach out to our HR team for assistance. We're here to help you succeed!</p>
    </div>
  );
}

export default Careers;
